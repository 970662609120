const PanelContainer = {
  baseStyle: {
    p: '30px 15px',
    minHeight: 'calc(100vh - 123px)',
  },
};

export const PanelContainerComponent = {
  components: {
    PanelContainer,
  },
};
