import React from 'react';
import Head from 'next/head';
import { Container, Link, Text } from '@chakra-ui/react';

export default function Maintenance() {
  return (
    <>
      <Head>
        <title>メンテナンス中です</title>
        <meta name="robots" content="noindex" />
      </Head>
      <Container w={'100%'} bg={'white'} p={12} my={12}>
        <Text fontWeight={'bold'} fontSize={'2xl'} pb={2}>
          メンテナンス中です
        </Text>
        {process.env.NEXT_PUBLIC_MAINTENANCE_PERIOD && (
          <Text pb={3}>
            メンテナンス期間:
            <Text as="span" ml={2} fontWeight={'bold'}>
              {process.env.NEXT_PUBLIC_MAINTENANCE_PERIOD}
            </Text>
          </Text>
        )}
        <Text pb={1}>現在システムメンテナンスのため、こちらのページはご利用いただけません。</Text>
        <Text pb={1}>ご不便をお掛けいたしますが、メンテナンス終了までしばらくお待ちください。</Text>
        <Text>
          なお、不具合であると思われる場合は、
          <Link
            href={'https://forms.gle/oZTrxU5i32bHydRZ8'}
            target={'_blank'}
            color={'blue.600'}
            textDecoration={'underline'}
          >
            お問い合わせ(Google Formが開きます)
          </Link>
          をいただけますと幸いです。
        </Text>
      </Container>
    </>
  );
}
