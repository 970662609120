import { createContext, useState, useContext } from 'react';
import { useBoolean } from '@chakra-ui/react';

export const FloatingBannerContext = createContext({
  isVisible: true,
  onClose: () => {},
});

export const useFloatingBanner = () => useContext(FloatingBannerContext);

const FloatingBannerProvider = ({ children }) => {
  const [isVisible, setVisible] = useBoolean(true);

  return (
    <FloatingBannerContext.Provider
      value={{
        isVisible,
        onClose: setVisible.off,
      }}
    >
      {children}
    </FloatingBannerContext.Provider>
  );
};

export default FloatingBannerProvider;
