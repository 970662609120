// @ts-nocheck
import { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import axios from 'axios';
import * as Sentry from '@sentry/nextjs';

export const AuthContext = createContext({});
export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const router = useRouter();
  const [currentUser, setCurrentUser] = useState(''); // {} だと、booleanにしたときに、trueになることに注意

  const currentUserId = currentUser ? currentUser.id : null;

  // logout
  const handleLogout = async () => {
    axios
      .post(`${process.env.NEXT_PUBLIC_API_HOST}/users/logout`, '', {
        withCredentials: true,
      })
      .then(async () => {
        setCurrentUser('');
        await router.push('/login');
      });
  };

  // update user
  const updateUser = async () => {
    axios
      .get(`${process.env.NEXT_PUBLIC_API_HOST}/users/me`, {
        withCredentials: true,
      })
      .then(async (res) => {
        // ok の場合ユーザーをセット
        if (res.data.status === 'ok' && res.data.user.email_verified) {
          setCurrentUser(res.data.user);

          // Sentry に送信するユーザーデータをセットする
          Sentry.configureScope((scope) =>
            scope.setUser({
              id: res.data.user.id,
              username: res.data.user.name,
            })
          );
        } else if (res.data.status === 'ok' && !res.data.user.email_verified) {
          setCurrentUser(res.data.user);
          router.push(`/verify-email/${res.data.user.email_hash}`);
        } else {
          setCurrentUser('');
          // 未ログイン時にはログイン画面へ
          if (window.location.pathname !== '/login') {
            await router.push('/login');
          }
        }
      })
      .catch(async () => {
        setCurrentUser('');
        // 未ログイン時にはログイン画面へ
        await router.push('/login');
      });
  };

  const handleDelete = async () => {
    await axios.post(`${process.env.NEXT_PUBLIC_API_HOST}/users/delete`, '', {
      withCredentials: true,
    });

    alert('会員を退会しました。ご利用ありがとうございました。');
    setCurrentUser('');
    await router.push('/login');
  };

  const setUserCurrentProjectId = (currentProjectId) => {
    if (!currentUser) return;

    setCurrentUser({
      ...currentUser,
      currentProjectId: currentProjectId,
    });
  };

  const setLastLoginAt = () => {
    localStorage.setItem('last-login-at', new Date().toLocaleString('ja-JP'));
  };

  useEffect(() => {
    async function auth() {
      console.log(router.pathname);
      // ログインしていなくても見れる画面
      // /loginはログイン時にダッシュボードにリダイレクトさせたいので、Loginコンポーネント側で制御している
      if (
        router.pathname === '/terms' ||
        router.pathname === '/privacy' ||
        router.pathname === '/tos' ||
        router.pathname === '/contents-policy' ||
        router.pathname === '/free' ||
        router.pathname === '/free/[id]' ||
        router.pathname === '/signup' ||
        router.pathname === '/password-reset' ||
        router.pathname === '/forgot-password'
      ) {
        return;
      }
      await updateUser();
    }

    // ログイン済みの場合は何もしない
    if (currentUser) return;
    auth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        handleLogout,
        handleDelete,
        updateUser,
        currentUser,
        currentUserId,
        setUserCurrentProjectId,
        setLastLoginAt,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
