import { useEffect, useState } from 'react';
import {
  Button,
  CloseButton,
  Flex,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionPanel,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

const TutorialTourItem = ({
  index,
  stepLength,
  onClose,
  backText,
  onClickBack,
  actionText,
  onClickAction,
  children,
  isCollapsible,
  ...props
}) => {
  const [accordionIndex, setAccordionIndex] = useState([0]);

  useEffect(() => {
    setAccordionIndex([0]);
  }, [index]);

  return (
    <Accordion index={accordionIndex} allowMultiple onChange={setAccordionIndex} {...props}>
      <AccordionItem border={'none'}>
        {({ isExpanded }) => (
          <>
            <AccordionPanel pt={6} px={6} pb={4}>
              <CloseButton size="sm" color={'gray.400'} onClick={onClose} position="absolute" right={2} top={2} />
              <Text fontSize="md" mb={2}>
                {children}
              </Text>
              <Text fontSize="sm" color={'gray.400'} mt={6}>
                {index + 1} / {stepLength}
              </Text>
              <Flex justifyContent="space-between" alignContent="center" mt={1}>
                <Button
                  color={'gray.500'}
                  bg={'transparent'}
                  fontWeight={'normal'}
                  marginLeft={-4}
                  onClick={onClickBack}
                  size={'sm'}
                >
                  {backText}
                </Button>

                {onClickAction ? (
                  <Button
                    bg={'#3F5FF6'}
                    onClick={onClickAction}
                    _hover={{ bg: '3F5FF6', opacity: 0.8 }}
                    color={'white'}
                    size={'sm'}
                    marginRight={-2}
                    minW={20}
                  >
                    {actionText}
                  </Button>
                ) : (
                  <Text ml={2} fontSize="xs" alignSelf={'center'} color={'gray.400'}>
                    {actionText}
                  </Text>
                )}
              </Flex>
            </AccordionPanel>
            <AccordionButton hidden={!isCollapsible} minW={80} color={'gray.500'}>
              {!isExpanded && <Text>チュートリアルを再開する</Text>}
              <Box flex="1" textAlign="right" fontSize={14}>
                {isExpanded ? '折りたたむ' : ''}
              </Box>
              {isExpanded ? <ChevronUpIcon fontSize={20} /> : <ChevronDownIcon fontSize={20} />}
            </AccordionButton>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default TutorialTourItem;
