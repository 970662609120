import { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthProvider';
import { ToolContext } from './ToolProvider';

export const EditorContext = createContext({
  body: undefined,
  setBody: undefined,
  updateBody: undefined,
});
export const useEditor = () => useContext(EditorContext);

const EditorProvider = ({ children }) => {
  const exampleValue = [
    {
      type: 'paragraph',
      children: [
        { text: '自由に使えるメモ帳です。' },
        { text: '太字にしたり', bold: true },
        { text: '斜体にしたり', italic: true },
        { text: 'することもできます。' },
      ],
    },
  ];

  const [body, setBody] = useState(exampleValue);
  const { currentUserId } = useAuth();

  const fetch = () => {
    axios
      .get(`${process.env.NEXT_PUBLIC_API_HOST}/userMemos`, {
        withCredentials: true,
      })
      .then((res) => {
        const body = res.data.body.length > 0 ? JSON.parse(res.data.body) : exampleValue;
        setBody(body);
      })
      .catch(() => {});
  };
  const updateBody = async (body) => {
    setBody(body);

    await axios
      .post(
        `${process.env.NEXT_PUBLIC_API_HOST}/userMemos`,
        {
          body: JSON.stringify(body),
        },
        {
          withCredentials: true,
        }
      )
      .then(() => {
        return true;
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!currentUserId) return;
    fetch();
  }, [currentUserId]);

  return (
    <EditorContext.Provider
      value={{
        body,
        setBody,
        updateBody,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

export default EditorProvider;
