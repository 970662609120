import { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthProvider';

export const ToolContext = createContext({
  tools: undefined,
  isToolsLoading: undefined,
  addFavorite: undefined,
  removeFavorite: undefined,
});

export const useTool = () => useContext(ToolContext);

const ToolProvider = ({ children }) => {
  // state
  const [tools, setTools] = useState([]);
  const [isToolsLoading, setIsToolsLoading] = useState(true);

  // hooks
  const { currentUserId } = useAuth();

  // define functions
  const updateTools = (loading = true) => {
    setIsToolsLoading(loading);
    axios
      .get(`${process.env.NEXT_PUBLIC_API_HOST}/tools`, {
        withCredentials: true,
      })
      .then((res) => {
        setIsToolsLoading(false);
        setTools(res.data.tools);
      })
      .catch(() => {});
  };

  const addFavorite = (id) => {
    // client
    const newTools = tools.map((tool) => {
      if (Number(tool.id) === Number(id)) {
        return Object.assign({}, tool, { is_favorite: 1 });
      }
      return tool;
    });
    setTools(newTools);

    // request
    axios
      .post(
        `${process.env.NEXT_PUBLIC_API_HOST}/tools/favorites`,
        {
          tool_id: id,
        },
        {
          withCredentials: true,
        }
      )
      .then(() => {})
      .catch(() => {});
  };
  const removeFavorite = (id) => {
    // client
    const newTools = tools.map((tool) => {
      if (Number(tool.id) === Number(id)) {
        return Object.assign({}, tool, { is_favorite: 0 });
      }
      return tool;
    });
    setTools(newTools);

    // request
    axios
      .delete(`${process.env.NEXT_PUBLIC_API_HOST}/tools/favorites`, {
        withCredentials: true,
        data: {
          tool_id: id,
        },
      })
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    // 未ログイン状態では実行しない
    if (!currentUserId) return;
    updateTools();
  }, [currentUserId]);

  return (
    <ToolContext.Provider
      value={{
        tools,
        isToolsLoading,
        addFavorite,
        removeFavorite,
      }}
    >
      {children}
    </ToolContext.Provider>
  );
};

export default ToolProvider;
