import { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthProvider';

export const CategoryContext = createContext({
  categories: undefined,
  setCategories: undefined,
  isCategoriesLoading: undefined,
  setIsCategoriesLoading: undefined,
  updateCategories: undefined,
});

const CategoryProvider = ({ children, defaultCategories = [] }) => {
  const [categories, setCategories] = useState(defaultCategories);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(true);
  const { currentUserId } = useAuth();

  const updateCategories = (loading = true) => {
    setIsCategoriesLoading(loading);
    axios
      .get(`${process.env.NEXT_PUBLIC_API_HOST}/categories`, {
        withCredentials: true,
      })
      .then((res) => {
        setIsCategoriesLoading(false);
        setCategories(res.data.categories);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!currentUserId) return;
    updateCategories();
  }, [currentUserId]);

  return (
    <CategoryContext.Provider
      value={{
        categories,
        setCategories,
        isCategoriesLoading,
        setIsCategoriesLoading,
        updateCategories,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

export default CategoryProvider;
