import { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthProvider';

export const ProjectContext = createContext({
  projects: undefined,
  setProjects: undefined,
  isProjectLoading: undefined,
  setIsProjectLoading: undefined,
  updateProjects: undefined,
});

const ProjectProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [isProjectLoading, setIsProjectLoading] = useState(true);
  const { currentUserId } = useAuth();

  const updateProjects = () => {
    setIsProjectLoading(true);
    axios
      .get(`${process.env.NEXT_PUBLIC_API_HOST}/projects`, {
        withCredentials: true,
      })
      .then((res) => {
        setIsProjectLoading(false);
        setProjects(res.data.projects);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!currentUserId) return;
    updateProjects();
  }, [currentUserId]);

  return (
    <ProjectContext.Provider
      value={{
        projects,
        setProjects,
        isProjectLoading,
        setIsProjectLoading,
        updateProjects,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
