import { createContext, useState, useContext } from 'react';
import { useDisclosure } from '@chakra-ui/react';

export const SidebarContext = createContext({
  activeIndex: undefined,
  setActiveIndex: undefined,
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
});

export const useSidebar = () => useContext(SidebarContext);

const SidebarProvider = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <SidebarContext.Provider
      value={{
        activeIndex,
        setActiveIndex,
        isOpen,
        onOpen,
        onClose,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;
