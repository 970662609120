import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useMediaQuery } from '@chakra-ui/react';
import { TourProvider, useTour } from '@reactour/tour';
import { StarIcon } from '@chakra-ui/icons';
import { useAuth } from './AuthProvider';
import { useSidebar } from './SidebarProvider';
import TutorialTourItem from '../components/TutorialTour/TutorialTourItem';
import { breakpoints } from '../theme/foundations/breakpoints';
import usePrevious from '../hooks/usePrev';

export const TutorialTourContext = createContext({
  isOpen: false,
  currentStep: {},
  handleNextStep: () => {},
});

export const useTutorialTour = () => useContext(TutorialTourContext);

const TutorialTourProviderInner = ({ children }) => {
  const { isOpen, setIsOpen, currentStep: currentStepIndex, setCurrentStep, setSteps, onClickClose } = useTour();
  const prevIsOpen = usePrevious(isOpen);
  const prevCurrentStepIndex = usePrevious(currentStepIndex);
  const router = useRouter();
  const { currentUser } = useAuth();

  // コピー作成画面で回答がある状態かどうか
  const [hasToolAnswers, setHasToolAnswers] = useState(false);

  // router.push でページ遷移した際に trueにする
  const [isRouterPush, setIsRouterPush] = useState(false);
  const { isSubscribed, usage } = currentUser;
  const [isPc] = useMediaQuery(`(min-width: ${breakpoints.md})`, { ssr: true });
  const isSp = !isPc;
  const { onOpen: onOpenSidebar, onClose: onCloseSidebar } = useSidebar();
  const disableScroll = () => disableBodyScroll(document.body);
  const enableScroll = () => enableBodyScroll(document.body);

  const scrollableStepStyle = (selector) => ({
    maskArea: (base) => {
      const { y: prevY, height: prevHeight } = base;
      const targetY = document.querySelector(selector).offsetTop;
      // マスクの高さを再計算
      const height = prevY === 0 ? prevHeight - window.scrollY + targetY : prevHeight;

      return {
        ...base,
        height: height >= 0 ? height : 0,
      };
    },
    popover: (base) => ({
      ...base,
      padding: 0,
    }),
  });

  const steps = [
    {
      id: 'tour-initial',
      pathname: '/dashboard',
      action: () => {
        disableScroll();
      },
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="あとで見る"
          onClickBack={handleClose}
          actionText="開始する"
          onClickAction={() => setCurrentStep(currentStep + 1)}
        >
          Catchyへようこそ。 Catchyの基本的な使い方をご案内します。
          <br />
          チュートリアルは、あとから見ることもできます。
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-dashboard-page',
      pathname: '/dashboard',
      selector: '.tour-dashboard-page',
      position: 'center',
      action: () => {
        disableScroll();
      },
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          onClickBack={() => setCurrentStep(currentStep - 1)}
          actionText="次へ"
          onClickAction={() => setCurrentStep(currentStep + 1)}
          maxW={'274px'}
        >
          この画面では、おすすめツールや事例集を確認することができます。
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-dashboard-try-copy',
      pathname: '/dashboard',
      selector: '.tour-dashboard-try-copy',
      resizeObservables: ['.tour-dashboard-try-copy'],
      stepInteraction: true,
      padding: 6,
      position: 'bottom',
      action: () => {
        disableScroll();
      },
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          actionText="「短文を文章にする」をクリックして進む"
          onClickBack={() => setCurrentStep(currentStep - 1)}
        >
          「短文を文章にする」をクリックして、試しに文章を作ってみましょう。
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-tool-page',
      pathname: '/tools/66',
      selector: '.tour-tool-page',
      highlightedSelectors: ['.tour-tool-page'],
      mutationObservables: ['.tour-tool-page'],
      position: 'left',
      action: () => {
        disableScroll();
      },
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          onClickBack={async () => {
            setIsRouterPush(true);
            await router.push('/dashboard');
            setCurrentStep(currentStep - 1);
          }}
          actionText="次へ"
          onClickAction={() => setCurrentStep(currentStep + 1)}
        >
          この画面でコピーを作成できます。
        </TutorialTourItem>
      ),
    },
    isSp &&
      !isSubscribed && {
        id: 'tour-menu-hamburger-01',
        selector: '.tour-menu-hamburger',
        pathname: '/tools',
        stepInteraction: true,
        position: 'right',
        padding: 16,
        action: () => {
          disableScroll();
        },
        content: ({ currentStep, setCurrentStep }) => (
          <TutorialTourItem
            index={currentStep}
            stepLength={steps.length}
            onClose={handleClose}
            backText="もどる"
            onClickBack={() => setCurrentStep(currentStep - 1)}
          >
            メニューを開きましょう
          </TutorialTourItem>
        ),
      },
    !isSubscribed && {
      id: 'tour-tool-credit',
      pathname: '/tools/66',
      selector: isSp ? '.tour-tool-credit-sp' : '.tour-tool-credit',
      action: () => {
        disableScroll();
      },
      padding: 8,
      position: 'left',
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          onClickBack={() => {
            isSp && onCloseSidebar();
            setCurrentStep(currentStep - 1);
          }}
          actionText="次へ"
          onClickAction={() => {
            isSp && onCloseSidebar();
            setCurrentStep(currentStep + 1);
          }}
        >
          クレジットは、Catchyでテキストを作成するために使用する、ポイントのようなものです。
          <br />
          会員登録時に10クレジット付与されます。
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-tool-example',
      pathname: '/tools/66',
      selector: '.tour-tool-example',
      position: 'top',
      padding: 16,
      stepInteraction: true,
      action: () => {},
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          actionText="「例文を入力する」をクリックして進む"
          onClickBack={() => {
            isSp && onOpenSidebar();
            setCurrentStep(currentStep - 1);
          }}
        >
          短文を入力しましょう。
          <br />
          ここでは例文を入力してみましょう。
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-tool-form',
      pathname: '/tools/66',
      selector: '.tour-tool-form',
      position: 'top',
      padding: 16,
      stepInteraction: true,
      action: () => {},
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          onClickBack={() => setCurrentStep(currentStep - 1)}
          actionText="次へ"
          onClickAction={() => setCurrentStep(currentStep + 1)}
        >
          例文をもとに自由に編集してみましょう
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-tool-create-button',
      pathname: '/tools/66',
      selector: '.tour-tool-create-button',
      position: 'bottom',
      padding: 8,
      stepInteraction: true,
      highlightedSelectors: ['.tour-create-button'],
      mutationObservables: ['.tour-create-button'],
      action: () => {},
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          onClickBack={() => setCurrentStep(currentStep - 1)}
          actionText="「作成する」をクリックして進む"
          onClickAction={hasToolAnswers ? () => setCurrentStep(currentStep + 1) : null}
        >
          「作成する」ボタンをクリックしてコピーを作成しましょう
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-tool-answer',
      pathname: '/tools/66',
      selector: '.tour-tool-answer',
      mutationObservables: ['.tour-tool-answer'],
      stepInteraction: true,
      padding: 8,
      position: 'bottom',
      action: () => {
        disableScroll();
        setHasToolAnswers(true);
      },
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          onClickBack={() => setCurrentStep(currentStep - 1)}
          actionText="次へ"
          onClickAction={() => setCurrentStep(currentStep + 1)}
        >
          作成されたコピーは編集することができます。
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-tool-answer-more',
      pathname: '/tools/66',
      selector: '.tour-tool-answer-more',
      stepInteraction: true,
      padding: 8,
      position: 'bottom',
      action: () => {
        disableScroll();
      },
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          onClickBack={() => setCurrentStep(currentStep - 1)}
          actionText="次へ"
          onClickAction={() => setCurrentStep(currentStep + 1)}
        >
          気に入ったコピーがない場合は、
          <br />
          「さらに作成する」ボタンをクリックしましょう
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-tool-favorite',
      pathname: '/tools/66',
      selector: '.tour-tool-favorite',
      mutationObservables: [`[data-tour-id="mask-position-recompute"]`],
      styles: scrollableStepStyle('.tour-tool-favorite'),
      stepInteraction: true,
      position: [8, 8],
      action: () => {
        enableScroll();
        window.scrollTo({
          top: document.querySelector('.tour-tool-answers').offsetTop - 180 ?? 0, // ポップアップの高さ
          behavior: 'smooth',
        });
      },
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          actionText="「保存する」をクリックして進む"
          onClickBack={() => setCurrentStep(currentStep - 1)}
        >
          「 <StarIcon color={'gray.400'} /> 保存する」をクリックして
          <br />
          気に入ったコピーを保存してみましょう。
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-tool-to-favorite-page',
      pathname: '/tools/66',
      selector: '.tour-tool-to-favorite-page',
      stepInteraction: true,
      position: 'bottom',
      action: () => {
        disableScroll();
      },
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          actionText="「保存済み」をクリックして進む"
          onClickBack={() => setCurrentStep(currentStep - 1)}
        >
          「保存済み」をクリックして
          <br />
          保存したコピーを確認しましょう
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-tool-favorite-page',
      pathname: '/tools/66',
      selector: '.tour-tool-page',
      stepInteraction: false,
      position: [8, 8],
      action: () => {
        enableScroll();
      },
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          onClickBack={async () => {
            setIsRouterPush(true);
            await router.push('/dashboard');
            setCurrentStep(steps.findIndex((step) => step.id === 'tour-dashboard-try-copy'));
          }}
          actionText="次へ"
          onClickAction={() => setCurrentStep(currentStep + 1)}
        >
          この画面で保存したコピーを確認できます
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-tool-back-to-tools',
      pathname: '/tools/66',
      selector: '.tour-tool-back-to-tools',
      stepInteraction: true,
      position: 'bottom',
      padding: 8,
      action: () => {
        disableScroll();
      },
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          actionText="「一覧へ戻る」をクリックして進む"
          onClickBack={() => setCurrentStep(currentStep - 1)}
        >
          他の生成ツールを見てみましょう
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-tools-page',
      selector: '.tour-tools-page',
      pathname: '/tools',
      stepInteraction: false,
      position: [8, 8],
      action: () => {
        enableScroll();
      },
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          onClickBack={async () => {
            setIsRouterPush(true);
            await router.push('/tools/66');
            setCurrentStep(steps.findIndex((step) => step.id === 'tour-tool-example'));
          }}
          actionText="次へ"
          onClickAction={() => setCurrentStep(currentStep + 1)}
        >
          この画面では、125種類の生成ツール一覧を見ることが出来ます
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-tools-search',
      selector: '.tour-tools-search',
      pathname: '/tools',
      stepInteraction: true,
      position: 'top',
      padding: 8,
      action: () => {
        disableScroll();
      },
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          onClickBack={() => setCurrentStep(currentStep - 1)}
          actionText="次へ"
          onClickAction={() => setCurrentStep(currentStep + 1)}
        >
          検索やタグを使って興味があるものを探してみましょう。
        </TutorialTourItem>
      ),
    },
    isSp && {
      id: 'tour-menu-hamburger-02',
      selector: '.tour-menu-hamburger',
      pathname: '/tools',
      stepInteraction: true,
      position: 'right',
      padding: 16,
      action: () => {
        disableScroll();
      },
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          onClickBack={() => setCurrentStep(currentStep - 1)}
        >
          メニューを開きましょう
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-menu-sidebar',
      selector: isSp ? '.tour-menu-sidebar-sp' : '.tour-menu-sidebar',
      pathname: '/tools',
      stepInteraction: false,
      position: 'right',
      action: () => {
        disableScroll();
      },
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          onClickBack={() => {
            onCloseSidebar();
            setCurrentStep(currentStep - 1);
          }}
          actionText="次へ"
          onClickAction={() => setCurrentStep(currentStep + 1)}
        >
          メニューからも生成ツールを確認できます
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-menu-to-dashboard',
      selector: isSp ? '.tour-menu-to-dashboard-sp' : '.tour-menu-to-dashboard',
      pathname: '/tools',
      stepInteraction: true,
      position: 'right',
      action: () => {
        disableScroll();
      },
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          actionText="「ダッシュボード」をクリックして進む"
          onClickBack={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          もう一度「ダッシュボード」を見てみましょう
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-dashboard-case',
      selector: '.tour-dashboard-case',
      pathname: '/dashboard',
      stepInteraction: true,
      position: 'right',
      padding: 8,
      action: () => {
        disableScroll();
      },
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          onClickBack={async () => {
            setIsRouterPush(true);
            await router.push('/tools');
            isSp && onOpenSidebar();
            setCurrentStep(currentStep - 1);
          }}
          actionText="次へ"
          onClickAction={() => setCurrentStep(currentStep + 1)}
        >
          使い道に迷った場合は事例集を活用しましょう
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-dashboard-chat',
      selector: '#chatplusview',
      pathname: '/dashboard',
      stepInteraction: false,
      position: 'top',
      action: () => {
        disableScroll();
      },
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleClose}
          backText="もどる"
          onClickBack={() => setCurrentStep(currentStep - 1)}
          actionText="次へ"
          onClickAction={() => setCurrentStep(currentStep + 1)}
        >
          何かわからないことがあれば
          <br />
          チャットで気軽に聞いてみましょう
        </TutorialTourItem>
      ),
    },
    {
      id: 'tour-dashboard-end',
      selector: 'body',
      pathname: '/dashboard',
      stepInteraction: false,
      position: 'top',
      action: () => {
        disableScroll();
      },
      content: ({ currentStep, setCurrentStep }) => (
        <TutorialTourItem
          index={currentStep}
          stepLength={steps.length}
          onClose={handleEnd}
          backText="もどる"
          onClickBack={() => setCurrentStep(currentStep - 1)}
          actionText="終了する"
          onClickAction={handleEnd}
        >
          これでチュートリアルは終了です。
          <br />
          引き続き、Catchyをお楽しみください。
          <br />
          <br />
          チュートリアルは「設定」画面からいつでも再び見ることができます。
        </TutorialTourItem>
      ),
    },
  ].filter((step) => step);

  const currentStep = steps[currentStepIndex] ?? {};

  useEffect(() => {
    setSteps(steps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, hasToolAnswers, isSp]);

  const handleNextStep = useCallback(
    () => setCurrentStep((currentStepIndex) => currentStepIndex + 1),
    [setCurrentStep]
  );
  const handleClose = () => {
    onClickClose({ setIsOpen });
  };
  const handleEnd = () => setIsOpen(false);

  const handleShowGA = (isFromSetting) => {
    window.dataLayer?.push &&
      window.dataLayer?.push({
        event: 'show_tutorial',
        event_category: isFromSetting ? 'settings' : 'signup',
        value: 1,
      });
  };

  const handleShowStepGA = (category) => {
    window.dataLayer?.push &&
      window.dataLayer?.push({
        event: 'show_tutorial_step',
        event_category: category ?? 'next',
        step_id: currentStep.id,
        value: 1,
      });
  };

  const handleCloseGA = () => {
    window.dataLayer?.push &&
      window.dataLayer?.push({
        event: 'close_tutorial',
        step_id: currentStep.id,
        value: 1,
      });
  };

  useEffect(() => {
    // ステップ遷移時のアクション

    if (prevIsOpen && !isOpen) {
      // onClose
      handleCloseGA();
    }
    if (!currentUser || !isOpen) return;

    if (prevCurrentStepIndex + 1 === currentStepIndex) {
      // onNextStep
      handleShowStepGA();
    } else if (prevCurrentStepIndex - 1 === currentStepIndex) {
      // onBackStep
      handleShowStepGA('back');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex, isOpen]);

  useEffect(() => {
    // 画面遷移時のアクション

    if (router.pathname === '/dashboard' && router.query?.tutorial) {
      // SPはツアー表示しない
      if (!isSp) {
        // ツアー開始
        setSteps(steps);
        setCurrentStep(0);
        setIsOpen(true);
        handleShowGA(router.query?.setting);
        handleShowStepGA();
      }
      router.replace('/dashboard');
    }

    // ツアーが閉じている時はスキップ
    if (!isOpen) return;

    // <Link> で画面遷移した後に次ステップへ進める処理
    switch (router.asPath) {
      case '/dashboard':
        setHasToolAnswers(false);
        if (currentStep.id === 'tour-menu-to-dashboard') {
          setCurrentStep(currentStepIndex + 1);
          return;
        }
        break;
      case '/tools/66':
        if (currentStep.id === 'tour-dashboard-try-copy') {
          setCurrentStep(currentStepIndex + 1);
          setHasToolAnswers(false);
          return;
        }
        break;
      case '/tools':
        setHasToolAnswers(false);
        if (currentStep.id === 'tour-tool-back-to-tools') {
          setCurrentStep(currentStepIndex + 1);
          return;
        }
        break;
      default:
        setIsOpen(false);
        break;
    }

    // ブラウザバックなど、ツアーのアクション以外で画面遷移した場合は閉じる
    if (router.asPath !== currentStep.pathname && !isRouterPush) {
      setIsOpen(false);
    }
    setIsRouterPush(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, router.query?.tutorial]);

  const [maskRecomputeId, setMaskRecomputeId] = useState('');
  useEffect(() => {
    // 枠内でスクロールしたときの処理
    let timer = null;
    const handleScroll = () => {
      clearTimeout(timer);
      timer = setTimeout(function () {
        setMaskRecomputeId(`${new Date().getTime()}`);
      }, 300);
    };
    if (isOpen) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isOpen, setMaskRecomputeId, router.asPath]);

  return (
    <TutorialTourContext.Provider
      value={{
        isOpen,
        currentStep,
        handleNextStep,
      }}
    >
      {/* マスクを再レンダリングするための要素 */}
      <span key={maskRecomputeId} data-tour-id="mask-position-recompute" />
      {children}
    </TutorialTourContext.Provider>
  );
};

const TutorialTourProvider = ({ children }) => {
  const enableScroll = () => enableBodyScroll(document.body);

  return (
    <TourProvider
      steps={[]}
      styles={{
        popover: (base) => ({
          ...base,
          padding: 0,
        }),
      }}
      position="center"
      beforeClose={enableScroll}
      onClickClose={({ setIsOpen }) => {
        if (confirm('チュートリアルを終了しますか？\nあとで設定画面から見ることもできます')) {
          setIsOpen(false);
        }
      }}
      disableInteraction
      disableKeyboardNavigation
      onClickHighlighted={(e) => {
        // 何もしない
        e.stopPropagation();
      }}
      onClickMask={() => {}}
      scrollSmooth
      showNavigation={false}
      showBadge={false}
      showCloseButton={false}
      disableDotsNavigation
      padding={0}
    >
      <TutorialTourProviderInner>{children}</TutorialTourProviderInner>
    </TourProvider>
  );
};

export default TutorialTourProvider;
