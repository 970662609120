// pages/_app.js
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../theme/theme.js';
import AuthProvider, { useAuth } from '../providers/AuthProvider';
import GoogleTagManager from '../components/googleTagManager';
import Maintenance from './maintenance';
import ToolProvider from '../providers/ToolProvider';
import ProjectProvider from '../providers/ProjectProvider';
import CategoryProvider from '../providers/CategoryProvider';
import SidebarProvider from '../providers/SidebarProvider';
import { CookiesProvider } from 'react-cookie';
import EditorProvider from '../providers/EditorProvider';
import TutorialTourProvider from '../providers/TutorialTourProvider';
import FloatingBannerProvider from '../providers/FloatingBannerProvider';

// パフォーマンス測定
export function reportWebVitals(metric) {
  const { name, label, value } = metric;
  if (label === 'web-vital' && process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production') {
    console.log(`${name.padStart(4)}: `, Math.round(value), `ms`);
  }
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  if (router.pathname === '/maintenance') {
    return (
      <ChakraProvider theme={theme}>
        {process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' && (
          <GoogleTagManager googleTagManagerId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID} />
        )}
        <Maintenance />
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <CookiesProvider>
        <AuthProvider>
          <ToolProvider>
            <ProjectProvider>
              <CategoryProvider>
                <SidebarProvider>
                  <EditorProvider>
                    <TutorialTourProvider>
                      <FloatingBannerProvider>
                        {process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' && (
                          <GoogleTagManager googleTagManagerId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID} />
                        )}
                        <Component {...pageProps} />
                      </FloatingBannerProvider>
                    </TutorialTourProvider>
                  </EditorProvider>
                </SidebarProvider>
              </CategoryProvider>
            </ProjectProvider>
          </ToolProvider>
        </AuthProvider>
      </CookiesProvider>
    </ChakraProvider>
  );
}

export default MyApp;
