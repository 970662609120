const CardBody = {
  baseStyle: {
    display: 'flex',
    width: '100%',
  },
};

export const CardBodyComponent = {
  components: {
    CardBody,
  },
};
